<template>
  <error-index :status="400" />
</template>

<script>
import ErrorIndex from "./error-index";
export default {
  components: {
    ErrorIndex
  }
};
</script>

<style></style>
